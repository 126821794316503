import React, { useEffect, useState, useRef, useCallback } from 'react';
import { View, StyleSheet, Animated } from 'react-native';
import { shuffle, sample, sampleSize } from 'lodash';

import { animate } from '../../helpers';

import Star from '../../assets/star.svg';

const bottomStars = [
  { left: '3.6%', top: '87%', width: 8 },
  { left: '4.5%', top: '72%', width: 5 },
  { left: '14.5%', top: '76%', width: 16 },
  { left: '22.5%', top: '68%', width: 9 },
  { left: '24%', top: '94%', width: 12 },
  { left: '35%', top: '80%', width: 6 },
  { left: '44%', top: '70%', width: 8 },
  { left: '59%', top: '61%', width: 5 },
  { left: '60%', top: '90%', width: 8 },
  { left: '64%', top: '77%', width: 10 },
  { left: '77%', top: '68%', width: 7 },
  { left: '84%', top: '90%', width: 10 },
  { left: '88%', top: '72%', width: 9 },
];

const getStars = () => {
  const stars = [
    { left: '-1%', top: '49%', width: 6 },
    { left: '-2%', top: '35%', width: 16 },
    { left: '4%', top: '23.1%', width: 7 },
    { left: '5%', top: '6.3%', width: 13 },
    { left: '7%', top: '53.9%', width: 7.2 },
    { left: '12%', top: '37.8%', width: 7 },
    { left: '12.4%', top: '60.9%', width: 6.4 },
    { left: '17%', top: '11.2%', width: 7 },
    { left: '19%', top: '52.5%', width: 7 },
    { left: '22%', top: '42%', width: 6 },
    { left: '26%', top: '14%', width: 12.4 },
    { left: '32%', top: '2.8%', width: 7 },
    { left: '33%', top: '58.8%', width: 7 },
    { left: '36%', top: '45.85%', width: 14 },
    { left: '40%', top: '10.85%', width: 7 },
    { left: '51%', top: '16.45%', width: 7 },
    { left: '55%', top: '7%', width: 17 },
    { left: '59%', top: '44.8%', width: 6 },
    { left: '68%', top: '15.4%', width: 7.7 },
    { left: '68%', top: '56%', width: 7 },
    { left: '73%', top: '1.4%', width: 7 },
    { left: '78%', top: '50.4%', width: 7 },
    { left: '80%', top: '8.4%', width: 13 },
    { left: '81%', top: '59.5%', width: 7 },
    { left: '84%', top: '17.5%', width: 7 },
    { left: '87%', top: '25.2%', width: 7 },
    { left: '88%', top: '44.8%', width: 7 },
    { left: '90%', top: '36.4%', width: 7 },
    { left: '94%', top: '4.9%', width: 7 },
    { left: '94%', top: '28.7%', width: 12 },
    { left: '97%', top: '16.8%', width: 6 },
    { left: '97.5%', top: '56%', width: 19 },

    // bottom stars
    ...bottomStars,
  ];

  return stars;
};

interface Props {
  isMobile: boolean;
}
type StarType = {
  left: string;
  top: string;
  width: number;
};

interface State {
  stars: StarType[];
  shining: number[];
}

const baseStars = getStars();
const starIdxs = baseStars.map((_, idx) => idx);
// const starIdxs = baseStars.slice(0, -bottomStars.length).map((_, idx) => idx);
const stageSize = 18;

export default class Stars extends React.Component<Props, State> {
  state: State = {
    stars: [],
    shining: [],
  };

  componentDidMount() {
    const stars = baseStars;
    const shining = sampleSize(starIdxs, stageSize);

    this.setState({ stars, shining }, this.setShine);

    // this.setShine();
    // setTimeout(twinkle, 1400);
  }

  shiningAnims = [...Array(stageSize)].map(() => new Animated.Value(1));

  twinkle = (anim: Animated.Value, slot: number) => {
    if (!anim) return console.error('now now what are you getting at');

    const { stars, shining } = this.state;

    const shiningIdx = shining[slot];
    const star = stars[shiningIdx];

    const xSize = Math.min(8, star.width);

    const scaleSize = Math.min(3.4, Math.max(1.4, 30 / star.width));
    const shineDuration = 1000 + 50 * xSize + Math.random() * 800;
    const dimDuration = 800 + 40 * xSize + Math.random() * 800;
    const stagger = 400 + Math.random() * 100 * xSize;

    Animated.stagger(stagger, [
      animate(anim, {
        toValue: scaleSize,
        duration: shineDuration,
      }),
      animate(anim, {
        toValue: 1,
        duration: dimDuration,
      }),
    ]).start(() => {
      this.nextShine(slot);
    });
  };

  nextShine = (slot: number) => {
    const { shining } = this.state;
    let shineIDx = shining[slot];

    while (shining.includes(shineIDx)) {
      shineIDx = sample(starIdxs) || shineIDx;
    }

    if (shineIDx !== undefined) shining[slot] = shineIDx;
    const anim = this.shiningAnims[slot];

    this.setState({ shining }, () => this.twinkle(anim, slot));
  };

  setShine = () => {
    this.shiningAnims.forEach((anim, idx) => {
      this.twinkle(anim, idx);
    });
  };

  render() {
    const { isMobile } = this.props;

    const { stars, shining } = this.state;

    // const twinkle = useCallback(() => {
    //   setStarState((prevValue) => {
    //     const newShining = shuffle([...prevValue.shining]);
    //     newShining.shift();
    //     const newIdx = Math.floor(Math.random() * prevValue.stars.length);
    //     newShining.push(newIdx);
    //     return { shining: newShining, stars: prevValue.stars };
    //   });

    //   // setTimeout(twinkle, 70 + Math.random() * 130);
    // }, []);

    const scale = isMobile ? 1 : 1.1;

    return (
      <View style={styles.starField}>
        {stars.map((s, idx) => {
          const shiningIdx = shining.findIndex((s) => s === idx);
          return (
            <Animated.View
              style={[
                styles.starBox,
                {
                  top: s.top,
                  left: s.left,
                  opacity: 1,
                  transform: [
                    {
                      scale:
                        shiningIdx >= 0 ? this.shiningAnims[shiningIdx] : 1,
                    },
                  ],
                },
              ]}
              key={idx}>
              <View style={styles.glow} />
              <Star width={s.width * scale} height={s.width * scale} />
            </Animated.View>
          );
        })}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  starField: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  starBox: {
    position: 'absolute',
    opacity: 0.9,
    left: '5%',
    top: '40%',
  },
  glow: {
    position: 'absolute',
    width: '100%',
    height: '100%',

    borderRadius: 50,
    // shadowOpacity: .4,
    // shadowColor: 'rgba(255,255,255,1)',
    // shadowRadius: 4,
  },
});
