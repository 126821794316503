import React from 'react';
import { View } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { unselectCoupon } from '../../globalStore';

import { BodyText, CouponTag } from '..';

import styles from './styles';

interface Props {
  storeId?: string;
  mb?: number;
  showAppliedMessage?: boolean;
  removeCoupon?: boolean;
}

const CouponDisplay = ({
  storeId,
  mb,
  showAppliedMessage,
  removeCoupon,
}: Props) => {
  const dispatch = useDispatch();

  const { storeCoupon, selectedCoupon } = useSelector((state: RootState) => {
    let storeCoupon: CouponType = null;
    if (storeId) {
      const storeCart = state.cartInfo.storeCarts[storeId];
      storeCoupon = storeCart?.appliedCoupon || null;
    }
    const { userCoupons, selectedCoupon } = state.userInfo;
    return { storeCoupon, userCoupons, selectedCoupon };
  });

  const hasStoreCoupon = !!storeCoupon?.slug;

  const appliedCoupon = hasStoreCoupon ? storeCoupon : selectedCoupon || null;

  if (!appliedCoupon) return null;

  const removeSelectedCoupon = () => {
    dispatch(unselectCoupon(storeId || ''));
  };

  return (
    <View style={[styles.couponInfoDisplay, { marginBottom: mb || 0 }]}>
      {showAppliedMessage && (
        <BodyText color='barnRed' bold={true} fontSize={18} mb={4}>
          Coupon Applied!
        </BodyText>
      )}

      <CouponTag
        coupon={appliedCoupon}
        selected={true}
        clickCoupon={() => undefined}
        removeCoupon={removeCoupon ? removeSelectedCoupon : undefined}
      />
    </View>
  );
};

export default CouponDisplay;
