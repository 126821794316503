import React from 'react';
import { View, Pressable, ViewStyle } from 'react-native';

import { colors } from '../../helpers';

import { BodyText } from '..';

import { YellowSoldOut } from '../../assets';

import styles from './styles';

interface Props {
  choice: ChoiceType;
  onPress: (choiceId: string) => void;
  extraStyle?: ViewStyle;
  isRadio?: boolean;
  marginRight?: number;
  fullWidth?: boolean;
  disabled?: boolean;
}

const PillBox = ({
  choice,
  onPress,
  extraStyle,
  isRadio,
  marginRight,
  fullWidth,
  disabled,
}: Props) => {
  const baseStyle = isRadio
    ? { width: '50%' }
    : {
        flex: 2,
        maxWidth: fullWidth ? '100%' : '50%',
        minWidth: fullWidth ? '100%' : '47%',
        marginBottom: 10,
        marginRight: !fullWidth && !!marginRight ? marginRight : 0,
      };

  const label = isRadio ? choice.name.split(' ')[0] : choice.name;

  const pillBlank = !choice.inStock && !choice.selected;
  const pillDisabled = !!disabled || !!pillBlank;

  return (
    <Pressable
      style={baseStyle}
      onPress={() => onPress(choice.id)}
      disabled={pillDisabled}>
      {/* @ts-ignore */}
      {({ hovered }) => (
        <View
          style={[
            styles.pill,
            extraStyle,
            {
              backgroundColor: pillBlank
                ? 'rgba(251,243,235,.6)'
                : choice.selected
                ? colors.primary
                : hovered
                ? colors.buttonHover
                : colors.offWhite,
              opacity: pillBlank ? 0.7 : 1,
            },
          ]}>
          <BodyText
            disabled={pillBlank}
            strike={!choice.inStock}
            color={choice.selected || hovered ? 'white' : 'midDark'}
            fontWeight='700'>
            {label}
          </BodyText>
          {!choice.inStock && (
            <View style={styles.soldOut}>
              <YellowSoldOut width={44} />
            </View>
          )}
        </View>
      )}
    </Pressable>
  );
};

export default PillBox;
