import React, { SVGProps, ReactNode } from 'react';
import { View, Animated, Easing, Image } from 'react-native';
import { isEqual } from 'lodash';

import { animate } from '../../../../helpers';

import {
  DrinkSVGIcon,
  DipSVGIcon,
  MascotBolt,
  PizzaSliceIcon,
} from '../../../../assets';

import styles from './styles';

interface Props {
  addedProducts: ProductType[];
}

interface State {}

type KernelType = {
  anim: Animated.Value;
  maxTop: number;
  left: string;
  icon: ReactNode;
  angle: number;
};

export default class PopcornAdd extends React.Component<Props, State> {
  popConfig: any = {};

  componentDidMount() {
    // setTimeout(this.firstPop, 500);
  }

  firstPop = () => {
    this.popConfig.originalProduct = this.newPop();
    this.forceUpdate();
    setTimeout(() => this.removePop(), 5000);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (!isEqual(prevProps.addedProducts, this.props.addedProducts)) {
      const newProduct = this.props.addedProducts.find(
        (p) =>
          !prevProps.addedProducts.find((pp) => pp.cartItemId === p.cartItemId)
      );
      if (newProduct?.cartItemId) {
        this.popConfig[newProduct.cartItemId] = this.newPop(newProduct);
        this.forceUpdate();
        setTimeout(() => this.removePop(newProduct), 5000);
      }
    }
  }
  removePop = (product?: ProductType) => {
    if (product) delete this.popConfig[product.id];
    else delete this.popConfig.originalProduct;
    this.forceUpdate();
  };

  newPop = (newProduct?: ProductType): KernelType[] => {
    // const quantity = Math.floor(15 + Math.random() * 15);
    const quantity = 2;
    const icons = {
      Drinks: <DrinkSVGIcon width={50} height={60} />,
      Dips: <DipSVGIcon width={35} height={40} />,
    } as any;
    const productIcon = newProduct?.category ? (
      icons[newProduct.category] || null
    ) : (
      <PizzaSliceIcon width={50} height={60} />
    );

    const mascot = <MascotBolt width={60} height={60} />;
    const basePosition = newProduct ? 40 : 50;
    const xPosition = Math.round(basePosition + Math.random() * 30);
    const kernels = [...Array(quantity)].map((_, idx) => {
      const productItem = idx === quantity - 1;

      const anim = new Animated.Value(0);

      const maxTop = -(110 + Math.random() * 40);

      const leftValue = productItem
        ? xPosition
        : xPosition + (-20 + Math.random() * 40);
      const left = `${leftValue}%`;

      const duration = Math.round(
        415 + Math.random() * 20 + Math.abs(maxTop * 6)
      );

      const timer = productItem ? 0 : 700;

      setTimeout(
        () =>
          animate(anim, {
            toValue: 1,
            duration,
            easing: Easing.linear,
          }).start(),
        timer
      );

      const baseAngle = productItem ? 100 : 40;

      const angle =
        Math.round(baseAngle + Math.random() * 50) *
        (Math.random() > 0.5 ? 1 : -1);

      const icon = productItem ? productIcon : mascot;
      return { anim, maxTop, left, icon, angle };
    });
    return kernels;
  };

  render() {
    return (
      <View style={styles.popcornBox}>
        {Object.keys(this.popConfig).map((id: string) => {
          const kernels = this.popConfig[id];
          if (!kernels) return null;

          return (
            <View style={styles.popRow} key={id}>
              {kernels.map((k: KernelType, idx: number) => {
                const top = k.anim.interpolate({
                  inputRange: [0, 0.4, 0.5, 0.6, 1],
                  outputRange: [
                    5,
                    k.maxTop * 0.82,
                    k.maxTop,
                    k.maxTop * 0.82,
                    20,
                  ],
                });

                const spin = k.anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', `${k.angle}deg`],
                });
                return (
                  <View style={styles.kernelColumn} key={idx}>
                    <Animated.View
                      style={[
                        styles.kernel,
                        {
                          top: top,
                          left: k.left,
                          transform: [{ rotate: spin }],
                        },
                      ]}
                      key={idx}>
                      <View />
                      {k.icon}
                    </Animated.View>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
    );
  }
}
