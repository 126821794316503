import React, { useCallback, useState, useEffect, useRef } from 'react';
import { View, StyleSheet } from 'react-native';
import { useDispatch } from 'react-redux';
import { uniqBy, countBy } from 'lodash';

import { globalStyles } from '../helpers';

import { unselectCoupon } from '../globalStore';

import { GrowView, CouponTag, BodyText, Block } from '.';

const usePrevious = (value: any) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface Props {
  coupons: UserCouponType[];
  clickCoupon?: (coupon: UserCouponType) => void;
  appliedCoupon?: CouponType;
  storeId?: string;
  message?: string;
  couponError?: string;
  special?: boolean;
}

const CouponList = ({
  coupons,
  appliedCoupon,
  clickCoupon,
  storeId,
  message,
  couponError,
  special,
}: Props) => {
  const dispatch = useDispatch();

  const [showUserCoupons, setShowUserCoupons] = useState(
    appliedCoupon?.slug ? false : true
  );

  const uniqCoupons = uniqBy(coupons, 'slug');
  const couponCounts = countBy(coupons, 'slug');

  const previousCoupon: string = usePrevious(appliedCoupon?.slug || '');

  useEffect(() => {
    if (uniqCoupons.length > 0 && !showUserCoupons && !appliedCoupon) {
      setShowUserCoupons(true);
    } else if (!!appliedCoupon && appliedCoupon.slug !== previousCoupon)
      setShowUserCoupons(false);
  }, [uniqCoupons, showUserCoupons, appliedCoupon]);

  const onClickCoupon = useCallback((coupon: UserCouponType) => {
    if (clickCoupon) clickCoupon(coupon);
    setTimeout(() => setShowUserCoupons(false), 50);
  }, []);

  let hasAvailable = !!appliedCoupon?.slug && uniqCoupons.length > 0;
  if (
    uniqCoupons.length === 1 &&
    appliedCoupon?.slug === uniqCoupons[0].slug &&
    couponCounts[appliedCoupon?.slug] <= 1
  )
    hasAvailable = false;

  return (
    <View style={styles.userCouponsBox}>
      <BodyText
        color='secondary'
        mt={appliedCoupon?.slug ? 0 : 20}
        mb={appliedCoupon?.slug ? 20 : 20}
        fontSize={16}
        bold={true}
        center={true}>
        {appliedCoupon?.slug ? (
          <>
            {!couponError && (
              <BodyText color='barnRed' bold={true} fontSize={18}>
                Coupon Applied!
              </BodyText>
            )}
          </>
        ) : (
          message || 'Select a coupon to apply it to the order'
        )}
      </BodyText>

      {!!appliedCoupon?.slug && (
        <>
          <CouponTag
            coupon={appliedCoupon}
            selected={true}
            showStars={!couponError}
            count={couponCounts[appliedCoupon.slug]}
            removeCoupon={
              special
                ? undefined
                : () => dispatch(unselectCoupon(storeId || ''))
            }
          />
          <Block height={20} />
        </>
      )}

      {hasAvailable && (
        <>
          <BodyText
            color='barnRed'
            underline={true}
            fontSize={16}
            mb={10}
            onPress={() => setShowUserCoupons(!showUserCoupons)}>
            {showUserCoupons ? 'Hide' : 'Show available coupons'}
          </BodyText>
        </>
      )}

      {showUserCoupons && (
        <GrowView
          initialHeight={0}
          maxHeight={uniqCoupons.length * 180}
          grow={showUserCoupons}
          duration={400}
          shrinkBack={true}
          style={styles.availableCoupons}>
          <>
            {uniqCoupons.map((coupon) => {
              const selected = coupon.slug === appliedCoupon?.slug;

              let count = couponCounts[coupon.slug];
              if (selected) count -= 1;

              if (count === 0) return null;

              return (
                <CouponTag
                  coupon={coupon}
                  dim={!!appliedCoupon?.slug}
                  count={count}
                  clickCoupon={() => onClickCoupon(coupon)}
                  key={coupon.id}
                />
              );
            })}
          </>
        </GrowView>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  userCouponsBox: {
    ...globalStyles.flexColumn,
    width: '100%',
    paddingBottom: 0,
  },
  availableCoupons: {
    ...globalStyles.flexColumn,
    width: '100%',
  },
});

export default CouponList;
