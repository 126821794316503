import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../helpers';

const styles = StyleSheet.create({
  couponTag: {
    marginBottom: 10,
    // marginLeft: 60,
    backgroundColor: colors.secondary,
    borderWidth: 2,
    borderColor: colors.dimMaroon,
    // opacity: 0.8,
    borderRadius: 4,
    width: '100%',
    maxWidth: 500,
  },
  selectedTag: {
    backgroundColor: colors.barnRed,
    shadowOffset: { width: 0, height: 1 },
    shadowRadius: 2,
    shadowOpacity: 0.9,
    shadowColor: colors.primary,
    borderWidth: 2,
    borderColor: colors.barnRed,
    // marginTop: 20,
    // marginBottom: 20,
    zIndex: -5,
    opacity: 1,
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    borderRadius: 4,
    zIndex: -1,
  },

  close: {
    ...globalStyles.flexRow,
    width: 35,
    height: 35,
    borderRadius: 40,
    position: 'absolute',
    top: -15,
    right: -10,
    zIndex: 20000,
    backgroundColor: colors.primary,
  },

  couponTextBox: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    minHeight: 70,
    maxHeight: 150,
    // height: 70,
    paddingVertical: 14,
    paddingHorizontal: 16,
    maxWidth: '100%',
    zIndex: 1000,
    elevation: 1000,
  },
  titleRow: {
    ...globalStyles.flexRow,
    marginBottom: 4,
  },
  priceTag: {
    position: 'absolute',
    left: -50,
  },
  starWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 200,
    width: '100%',
    height: '100%',
  },
  upperStars: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  starBox: {
    position: 'absolute',
  },
  countBadge: {
    ...globalStyles.flexRow,
    width: 38,
    height: 34,
    borderRadius: 20,
    backgroundColor: colors.white,
    marginLeft: 10,
  },
  adjustedBadge: {
    // width: 54,
    width: 0,
    opacity: 0,
    backgroundColor: colors.pink,
  },
  // testing mode
  testingStars: {
    ...globalStyles.flexRow,
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
});

export default styles;
