type ColorsType = {
  [id: string]: string;
};

const colors: ColorsType = {
  red: '#E10000',
  barnRed: '#8c0007',
  yellow: '#FFB81C',
  maroon: '#610D2A',
  dimMaroon: '#935D6C',
  pink: '#FEA8D2',
  blue: '#0076CE',
  darkBlue: '#002A77',
  green: '#6BA84F',
  darkGreen: '#466936',
  dough: '#DECCA6',
  white: 'white',
  offWhite: '#FBF3EB',
  grey: '#F2E6D8',
  midLight: '#D5C6B4',
  midDark: '#776B5F',
  brown: '#7E6959',
  lightBrown: '#B6A797',
  veryLightBrown: '#B9A997',
  black: '#30201A',
  modalPrimaryOverlay: '#7E120F',
  modalSecondaryOverlay: '#461822',
};

colors.primary = colors.red;
colors.secondary = colors.maroon;
colors.body = colors.offWhite;
colors.text = colors.lightBrown;
colors.textDark = colors.brown;
colors.textLight = colors.veryLightBrown;
colors.buttonHover = colors.barnRed;
colors.error = colors.red;

export default colors;
