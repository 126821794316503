import { StyleSheet } from 'react-native';
import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  boltBox: {
    transform: [{ rotate: '7deg' }],
    alignSelf: 'center',
    zIndex: 10,
  },
  chatBox: {
    ...globalStyles.flexColumn,
    top: 10,
    width: 400,
    alignSelf: 'center',
    zIndex: 10,
  },
  chatArrow: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderTopWidth: 0,
    borderRightWidth: 10,
    borderBottomWidth: 12,
    borderLeftWidth: 10,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderBottomColor: colors.dough,
    borderLeftColor: 'transparent',
  },
  chatBubble: {
    ...globalStyles.flexColumn,
    maxWidth: '80%',
    paddingVertical: 14,
    paddingHorizontal: 32,
    borderRadius: 20,
    backgroundColor: colors.dough,
  },
});

export default styles;
