import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

const styles = StyleSheet.create({
  infoBox: {
    backgroundColor: colors.body,
    padding: 16,
    width: '91%',
    minHeight: 10,
    marginBottom: 10,
  },
  mapBox: {
    height: 140,
    width: '100%',
    marginTop: 20,
    borderWidth: 1,
    borderColor: colors.textLight,
    borderRadius: 5,
    overflow: 'hidden',
  },

  couponInfo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  couponBox: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: colors.midLight,
    borderRadius: 5,
  },
  couponInput: {
    height: 44,
    flex: 1,
    outlineStyle: 'none',
    paddingVertical: 10,
    paddingHorizontal: 16,
    backgroundColor: colors.white,
    overflow: 'hidden',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    fontSize: 18,
    borderWidth: 1,
    borderColor: 'transparent',
    color: colors.secondary,
  },
  activeCoupon: {
    color: colors.white,
    backgroundColor: colors.secondary,
    opacity: 1,
  },
  couponButton: {
    ...globalStyles.flexRow,
    width: 74,
    height: '100%',
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: colors.midLight,
  },
  activeButton: {
    backgroundColor: colors.secondary,
  },
  removeButton: {
    backgroundColor: colors.primary,
  },

  couponError: {
    position: 'relative',
    bottom: -8,
    marginHorizontal: 'auto',
    alignSelf: 'center',
    fontSize: 16,
    textAlign: 'center',
  },
  infoRow: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
  },
});

export default styles;
