import React from 'react';
import { Pressable, Linking, ViewStyle } from 'react-native';

import { getEnvVariable, colors, logError } from '../../helpers';
import IconSvg from './IconSvg';

import styles from './styles';

type SocialName = 'share' | 'instagram' | 'tiktok';

const instagramUrl = getEnvVariable('REACT_APP_INSTAGRAM_URL');
const tiktokUrl = getEnvVariable('REACT_APP_TIKTOK_URL');

const goToUrl = (link: string) => {
  if (!link) return;
  Linking.canOpenURL(link).then((supported) => {
    if (supported) Linking.openURL(link);
    else console.error('Linking not supported');
  });
};

const shareAction = async () => {
  try {
    await navigator.share({
      url: window.location.pathname,
      title: 'Stellar Pizza',
    });
  } catch (e: any) {
    const isAbortError = !!e.toString && e.toString().includes('AbortError');
    if (!isAbortError) logError(e);
  }
};

type ConfigParams = {
  name: SocialName;
  iconSize?: number | string;
  iconColor?: string;
};

const getConfig = ({ name, iconSize, iconColor }: ConfigParams) => {
  const configs = {
    share: {
      action: shareAction,
      icon: <IconSvg name='share' size={iconSize} color={iconColor} />,
    },
    instagram: {
      action: () => goToUrl(instagramUrl),
      icon: <IconSvg name='instagram' size={iconSize} color={iconColor} />,
    },
    tiktok: {
      action: () => goToUrl(tiktokUrl),
      icon: <IconSvg name='tiktok' size={iconSize} color={iconColor} />,
    },
  };
  return configs[name];
};

interface Props {
  name: SocialName;
  iconColor?: string;
  size?: string | number;
  iconSize?: string | number;
  background?: string;
  mr?: number | string;
}

const SocialIcon = ({
  name,
  size,
  iconSize,
  iconColor,
  background,
  mr,
}: Props) => {
  const config = getConfig({ name, iconSize, iconColor });
  if (!config) return null;

  const extraStyle: ViewStyle = {};

  if (size) {
    extraStyle.width = size;
    extraStyle.height = size;
  }
  extraStyle.backgroundColor = colors.secondary;
  if (background && colors[background])
    extraStyle.backgroundColor = colors[background];

  if (mr) extraStyle.marginRight = mr;

  return (
    <Pressable style={[styles.socialLink, extraStyle]} onPress={config.action}>
      {config.icon}
    </Pressable>
  );
};

export default SocialIcon;
