import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../../helpers';

import mobileStyles from './mobileStyles';

const maxContentWidth = 500;

const styles = StyleSheet.create({
  modalBox: {
    // position: 'absolute',
    width: 500,
    maxWidth: '80%',
    maxHeight: '85%',

    // minHeight: 0,
    // maxHeight: '80%',
    // backgroundColor: 'white',
    // flexGrow: 2,
    // alignSelf: 'center',
    // borderWidth: 10,
    // borderColor: 'hotpink',
    // flex: 1,
  },
  closeButton: {
    position: 'absolute',
    right: -20,
    top: -20,
    width: 40,
    height: 40,
    borderRadius: 30,
    backgroundColor: '#E10000',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    zIndex: 4,
    elevation: 2,
  },
  pizzaTop: {
    width: '100%',
    left: 0,
    top: 0,
    // zIndex: 20,
    height: 60,
    backgroundColor: colors.body,
    overflow: 'hidden',
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
    borderBottomWidth: 1,
    borderBottomColor: colors.textLight,
    // opacity: 0.9,
    // marginBottom: 20,
  },
  bottomBackground: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '50%',
    backgroundColor: 'white',
  },

  pizzaTopImage: {
    width: '100%',
    height: '100%',
    transform: [{ scale: 1.2 }],
    opacity: 0.9,
  },
  section: {
    backgroundColor: colors.white,
    paddingVertical: 30,
    paddingHorizontal: 40,
    width: '100%',
    maxWidth: maxContentWidth * 1.2,
    alignSelf: 'center',
  },
  detailRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    // alignContent: 'flex-start',
    // alignItems: 'flex-start',
    // alignContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
    userSelect: 'none',

    // borderWidth: 1,
    // borderColor: 'hotpink',
  },
  titleRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    left: -1,
    // justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    // marginBottom: 10,
    userSelect: 'none',
    // maxWidth: '85%',
  },
  productImage: {
    // position: 'absolute',
    // right: -80,
    // bottom: -26,
    // right: -7,
    marginRight: 10,
    width: 90,
    height: 100,
    marginBottom: 15,
    // marginLeft: 5,
    // transform: [{ translateY: -5 }],
  },
  sideImage: {
    width: 60,
    height: 85,
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  pizzaImage: {
    top: 12,
    width: 130,
    height: 90,
    borderRadius: 50,
    // shadowColor: colors.black,
    // shadowOffset: { width: 0, height: -2 },
    // shadowRadius: 2,
    // shadowOpacity: 0.4,
    // padding: 20,
  },
  merchImage: {
    width: 130,
    height: 85,
    right: 5,
  },
  productName: {
    fontSize: 32,
    lineHeight: 32,
    marginBottom: 10,
    maxWidth: '85%',
  },
  emptyLine: {
    width: '100%',
    borderTopWidth: 1,
    borderTopColor: colors.grey,
  },
  optionsBox: {
    ...globalStyles.flexRow,
    paddingHorizontal: 40,
    paddingVertical: 20,
    minHeight: 280,
    borderColor: colors.grey,
    borderBottomWidth: 1,
    borderTopWidth: 1,
    backgroundColor: colors.white,

    // shadowOffset: { width: 10, height: -2 },
    // shadowOpacity: 0.2,
    // shadowRadius: 14,
    // shadowColor: colors.primary,
  },
  optionsContent: {
    flex: 1,
    maxWidth: maxContentWidth,
  },
  disabledMessage: {
    alignSelf: 'center',
    paddingVertical: 14,
    paddingHorizontal: 50,
    borderRadius: 10,
    backgroundColor: colors.barnRed,
    position: 'absolute',
    top: 0,
    zIndex: 100,
  },
  errorMessage: {
    ...globalStyles.flexRow,
    minHeight: 20,
    marginVertical: 0,
    paddingVertical: 5,
    fontSize: 15,
    fontWeight: '700',
    borderRadius: 10,
  },
  showErrorMessage: {
    color: colors.primary,
    minHeight: 30,
    // backgroundColor: colors.primary,
    marginVertical: 20,
  },
  addButton: {
    ...globalStyles.flexRow,
    justifyContent: 'center',
    width: '100%',
    height: 60,
    backgroundColor: colors.secondary,
    borderRadius: 100,
    paddingHorizontal: 30,
    userSelect: 'none',
  },
  storesClosedDisplay: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    paddingVertical: 20,
  },
  imageRow: {
    ...globalStyles.flexRow,
    width: '100%',
    // transform: [{ translateY: -40 }],
    position: 'absolute',
    zIndex: 100,
    // backgroundColor: colors.yellow,
  },
});

export default { ...styles, ...mobileStyles };
