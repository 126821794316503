import { StyleSheet } from 'react-native';
import { colors } from '../../../helpers';

const styles = StyleSheet.create({
  wrapper: {
    width: '91%',
  },
  contactBox: {
    width: '100%',
    padding: 16,
    backgroundColor: colors.body,
  },
  inputBox: {
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.midLight,
    backgroundColor: colors.white,
    paddingVertical: 10,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 24,
    borderRadius: 4,
    opacity: 0.9,
  },
  errorInput: {
    borderColor: colors.error,
  },
  focusedInput: {
    opacity: 1,
    outlineStyle: 'none',
    borderColor: colors.secondary,
  },
});

export default styles;
