import React, { useEffect, useRef } from 'react';
import { ViewProps, ViewStyle, Animated } from 'react-native';
import { globalStyles, animate } from '../helpers';

interface Props extends ViewProps {
  fadeIn?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
  flexColumn?: boolean;
  maxWidth?: number | string;
  duration?: number;
  appear?: boolean;
  delay?: number;
}

const CustomView = (props: Props) => {
  const {
    fadeIn,
    children,
    fullWidth,
    flexColumn,
    maxWidth,
    duration,
    appear,
    delay,
  } = props;

  const viewOpacity = useRef(new Animated.Value(fadeIn ? 0 : 1)).current;

  useEffect(() => {
    setTimeout(() => {
      if (fadeIn && appear !== false)
        animate(viewOpacity, {
          toValue: 1,
          duration: duration || 400,
        }).start();
    }, delay || 0);
  }, [appear]);

  const extraStyle: ViewStyle = flexColumn
    ? { ...globalStyles.flexColumn }
    : {};

  if (fullWidth) extraStyle.width = '100%';
  if (maxWidth) extraStyle.maxWidth = maxWidth;

  return (
    <Animated.View
      {...props}
      style={[props.style, extraStyle, { opacity: viewOpacity }]}>
      {children}
    </Animated.View>
  );
};

export default CustomView;
