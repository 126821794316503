import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  View,
  Pressable,
  Animated,
  useWindowDimensions,
  Image,
  Easing,
} from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-native';

import { colors, getTimeString, animate, isIos } from '../../helpers';

import {
  readUser,
  setLoginModal,
  readUserOrders,
  setReferralModal,
} from '../../globalStore';

import {
  Link,
  BodyText,
  TitleText,
  GrowView,
  StarLoader,
} from '../../components';

import CustomView from '../../components/CustomView';

import { getOpenOrders } from './recentOrders';

import {
  RightArrow,
  flatCheese,
  croppedSupreme,
  croppedPepp,
} from '../../assets';

import styles from './styles';

let currentUpdate = 0;

interface Props {
  initialOrders: FilledOrderType[] | null;
  openStoreOrders: FilledOrderType[];
  setOpenStoreOrders: (openStoreOrders: FilledOrderType[]) => void;
  setOrderHeight: (height: number) => void;
  backgroundHeight: number;
  userCoupons: UserCouponType[];
  referralAvailable: boolean;
  ordersReady: boolean;
}

const HomeContent = ({
  initialOrders,
  ordersReady,
  openStoreOrders,
  setOpenStoreOrders,
  setOrderHeight,
  backgroundHeight,
  userCoupons,
  referralAvailable,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(readUser);
  const appConfig = useSelector((state: RootState) => state.appInfo.appConfig);

  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const userOrders = useSelector(readUserOrders);

  const ordersHeightAnim = useRef(
    new Animated.Value(
      (initialOrders && initialOrders.length > 0) ||
      (!!userCoupons && userCoupons!.length > 0)
        ? 200
        : 0
    )
  ).current;

  const spinAnim = useRef(new Animated.Value(0)).current;

  const spinPizza = useCallback(() => {
    spinAnim.setValue(0);
    animate(spinAnim, {
      toValue: 1,
      duration: 180000,
      easing: Easing.linear,
    }).start(spinPizza);
  }, [spinAnim]);

  useEffect(() => {
    spinPizza();
  }, []);

  const showReferralModal = () => dispatch(setReferralModal(true));

  const pizzaSpin = spinAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  // const showReferralButton =
  //   referralAvailable ||
  //   (appConfig?.registerReferralEnabled &&
  //     !!user.publicId &&
  //     userOrders.length > 0);

  const showReferralButton = referralAvailable;

  useEffect(() => {
    const checkOpenOrders = async () => {
      const openOrders =
        !ordersReady && !!initialOrders
          ? initialOrders
          : await getOpenOrders(userOrders, user.id);
      currentUpdate += 1;
      setOpenStoreOrders(openOrders);

      if (openOrders.length > 0) {
        const productCount = openOrders
          .slice(0, 2)
          .flatMap((o) => o.products).length;

        const extraSpace = (productCount - 2) * 16;
        let height = openOrders.length > 1 ? 200 : 140;
        if (openOrders.length > 2) height = 240 + extraSpace;
        if (showReferralButton) height += 60;
        setOrderHeight(height);
        animate(ordersHeightAnim, {
          toValue: height,
          duration: 700,
          useNativeDriver: false,
        }).start();
      }
      //  else if (userCoupons.length > 0) {
      //   const height =
      //     userCoupons.length > 2 ? 450 : userCoupons.length > 1 ? 350 : 200;
      //   setOrderHeight(height);
      //   animate(ordersHeightAnim, {
      //     toValue: height,
      //     duration: 700,
      //   }).start();
      // }
      else {
        const extraCouponHeight =
          !!userCoupons && userCoupons.length > 0 ? 20 : 0;
        const height = (showReferralButton ? 110 : 0) + extraCouponHeight;

        setOrderHeight(height);
        const thisUpdate = currentUpdate;
        animate(ordersHeightAnim, {
          toValue: 0,
          duration: 500,
          useNativeDriver: false,
        }).start(() => {
          if (thisUpdate !== currentUpdate) return;
          setOpenStoreOrders(openOrders);
        });
      }
    };
    checkOpenOrders();
  }, [userOrders, userCoupons, user, showReferralButton]);

  const [profileClicked, setProfileClicked] = useState(false);

  useEffect(() => {
    if (!!profileClicked && !!user.loaded) {
      if (user.token) navigate('/profile');
      else dispatch(setLoginModal(true));
      setProfileClicked(false);
    }
  }, [user.token, user.loaded, profileClicked, setProfileClicked]);

  const profileAction = useCallback(() => {
    if (profileClicked) return;
    setProfileClicked(true);

    if (!user.loaded) return setTimeout(() => setProfileClicked(false), 5000);

    if (user.token) {
      if (userOrders.length > 0) navigate('/profile/orders');
      else navigate('/profile');
    } else dispatch(setLoginModal(true));
    setProfileClicked(false);
  }, [user.token, user.loaded, profileClicked, setProfileClicked, userOrders]);

  const buttonTop =
    !isMobile && backgroundHeight > 600 ? 0.05 * backgroundHeight : 0;

  return (
    <>
      <Animated.View
        style={[
          styles.openOrdersBox,
          isMobile && styles.mobileOrdersBox,
          { maxHeight: ordersHeightAnim },
        ]}>
        {openStoreOrders.length > 0 && (
          <>
            {openStoreOrders.slice(0, 2).map((order) => (
              <Link to={`/profile/orders/${order.id}`} key={order.id}>
                <View style={styles.openOrder} key={order.id}>
                  <View style={styles.buttonText}>
                    {order.state === 'ready_for_handoff' ? (
                      <BodyText
                        bold={true}
                        color='darkBlue'
                        mb={6}
                        fontSize={16}>
                        READY FOR PICKUP •{' '}
                        {getTimeString(
                          order.handoffTime || order.readyTime || ''
                        )}
                      </BodyText>
                    ) : order.state === 'completed' ? (
                      <BodyText
                        bold={true}
                        color='darkBlue'
                        mb={6}
                        fontSize={16}>
                        PICKED UP • {getTimeString(order.completedTime || '')}
                      </BodyText>
                    ) : (
                      <BodyText bold={true} color='text' mb={6} fontSize={16}>
                        PICKUP ETA {getTimeString(order.readyTime || '')}
                      </BodyText>
                    )}
                    {order.products.slice(0, 2).map((p) => (
                      <BodyText
                        color='textDark'
                        bold={true}
                        fontSize={18}
                        key={p.id}>
                        {p.quantity}x {p.name}
                      </BodyText>
                    ))}
                    {order.products.length > 2 && (
                      <BodyText color='textDark' bold={true} fontSize={18}>
                        ...
                      </BodyText>
                    )}
                  </View>
                  <View style={styles.orderArrowBox}>
                    <RightArrow width={28} height={28} />
                  </View>
                </View>
              </Link>
            ))}
            {openStoreOrders.length > 2 && (
              <Link to='/profile/orders'>
                <View style={[styles.openOrder]}>
                  <View style={styles.buttonText}>
                    <BodyText color='textDark' bold={true} fontSize={18}>
                      View All
                    </BodyText>
                  </View>
                  <View style={styles.orderArrowBox}>
                    <RightArrow width={28} height={28} />
                  </View>
                </View>
              </Link>
            )}
          </>
        )}
      </Animated.View>

      <View
        style={[
          styles.buttonBox,
          isMobile && styles.mobileButtonBox,
          { paddingTop: buttonTop },
        ]}>
        <CustomView fullWidth={true} fadeIn={true}>
          {showReferralButton && (
            <GrowView
              initialHeight={referralAvailable ? 100 : 0}
              grow={true}
              maxHeight={100}>
              <Pressable
                onPress={showReferralModal}
                style={styles.referralButton}>
                <TitleText
                  fontSize={22}
                  spacing={0.4}
                  color='white'
                  center={true}>
                  REFER A FRIEND
                </TitleText>
                <BodyText color='white'>(Get a pizza bonus)</BodyText>
              </Pressable>
            </GrowView>
          )}
          <Pressable
            onPress={() => navigate('/stores')}
            style={[
              styles.button,
              isMobile && styles.mobileButton,
              {
                backgroundColor: colors.primary,
              },
            ]}>
            <View
              style={[
                styles.newImage,
                {
                  top: 65,
                  paddingTop: 0,
                },
              ]}>
              <Animated.Image
                source={flatCheese}
                blurRadius={0}
                style={{
                  width: '100%',
                  height: '190%',
                  transform: [{ scaleX: 1.5 }, { rotate: pizzaSpin }],
                }}
                resizeMode='contain'
              />
            </View>
            <View style={styles.newContent}>
              <TitleText
                fontSize={32}
                spacing={0.4}
                color='white'
                center={true}
                style={[styles.buttonLabel, { marginTop: 5 }]}>
                ORDER NOW
              </TitleText>
            </View>
          </Pressable>
          <View style={styles.buttonRow}>
            <Pressable
              onPress={() => navigate('/menu')}
              style={[
                styles.button,
                isMobile && styles.mobileButton,
                styles.secondaryButton,
                {
                  backgroundColor: colors.secondary,
                  marginRight: 10,
                },
              ]}>
              <View
                style={[
                  styles.newImage,
                  {
                    borderRadius: 18,
                    top: isIos ? 45 : 30,
                    width: '100%',
                    left: -30,
                  },
                ]}>
                <Image
                  source={croppedSupreme}
                  blurRadius={0}
                  style={{ width: '170%', height: '130%' }}
                  resizeMode='contain'
                />
              </View>
              <View style={styles.newContent}>
                <TitleText
                  fontSize={28}
                  spacing={0.4}
                  color='white'
                  center={true}
                  style={styles.buttonLabel}>
                  MENU
                </TitleText>
              </View>
            </Pressable>
            <Pressable
              onPress={profileAction}
              style={[
                styles.button,
                isMobile && styles.mobileButton,
                styles.secondaryButton,
                {
                  backgroundColor: colors.black,
                },
              ]}>
              {!!profileClicked && (
                <View style={styles.profileLoader}>
                  <StarLoader small={true} />
                </View>
              )}
              <View
                style={[
                  styles.newImage,
                  {
                    borderRadius: 18,
                    bottom: isIos ? -70 : -98,
                    width: '100%',
                    left: -10,
                  },
                ]}>
                <Image
                  source={croppedPepp}
                  blurRadius={0}
                  style={{
                    width: '110%',
                    height: '75%',
                    transform: [{ skewY: '3deg' }, { rotate: '2deg' }],
                  }}
                />
              </View>
              <View style={styles.newContent}>
                <TitleText
                  fontSize={28}
                  spacing={0.4}
                  color='white'
                  center={true}
                  style={styles.buttonLabel}>
                  PROFILE
                </TitleText>
              </View>
            </Pressable>
          </View>
        </CustomView>
      </View>
    </>
  );
};

export default HomeContent;
