import React, { useCallback } from 'react';
import { ApplePayButton, useApplePay } from '@stripe/stripe-react-native';

import { logError, formatPrice } from '../../../../helpers';

import { BodyText, Block } from '../../..';

interface Props {
  order: OrderType;
  setErrorMessage: (error: string) => void;
  orderSuccess: () => void;
}

const NativeApplePay = ({ order, setErrorMessage, orderSuccess }: Props) => {
  const { isApplePaySupported, presentApplePay, confirmApplePayPayment } =
    useApplePay();

  const pay = useCallback(async () => {
    if (!isApplePaySupported)
      return logError('Apple pay invoked without being supported');

    if (!order.total) {
      setErrorMessage('Order total is not valid');
      return logError('Order total is not valid for stripe payment');
    }

    if (!order.payment?.clientSecret) {
      logError('Payment information could not be fully requested');
      return setErrorMessage(
        `Couldn't retrieve payment information from server, please try again`
      );
    }

    const { error } = await presentApplePay({
      cartItems: [
        {
          label: 'Stellar Pizza',
          amount: (order.total / 100).toFixed(2),
          paymentType: 'Immediate',
        },
      ],
      country: 'US',
      currency: 'USD',
      // shippingMethods: [
      //   {
      //     amount: '20.00',
      //     identifier: 'DPS',
      //     label: 'Courier',
      //     detail: 'Delivery',
      //   },
      // ],
      // requiredShippingAddressFields: ['emailAddress', 'phoneNumber'],
      requiredBillingContactFields: ['phoneNumber', 'name'],
    });
    if (error) {
      return logError(`Native Apple Pay error - ${error}`);
      // handle error
    }

    const { error: confirmError } = await confirmApplePayPayment(
      order.payment.clientSecret
    );

    if (confirmError) {
      return logError(`Native Apple Pay error - ${error}`);
      // handle error
    } else {
      orderSuccess();
    }
  }, [isApplePaySupported, order]);

  return (
    <>
      {isApplePaySupported && (
        <>
          <ApplePayButton
            onPress={pay}
            type='plain'
            buttonStyle='black'
            borderRadius={4}
            style={{
              width: '100%',
              height: 50,
            }}
          />
          <BodyText color='textDark' mt={56} mb={14}>
            Or pay with card
          </BodyText>
        </>
      )}
    </>
  );
};

export default NativeApplePay;
