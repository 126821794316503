import React, {
  useRef,
  createRef,
  useEffect,
  useState,
  useCallback,
} from 'react';
import {
  Pressable,
  View,
  Platform,
  NativeSyntheticEvent,
  LayoutRectangle,
  TextStyle,
  Animated,
  Image,
  FlatList,
  ScrollView,
} from 'react-native';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';

import { animate, getCombos, isDesktop } from '../../helpers';

import { TitleText, BodyText, Block, MenuToggle, ComboBox } from '..';

import ProductButton1 from './ProductButton1';

import styles from './styles';

const isWeb = Platform.OS === 'web';

type WebLayoutChangeEvent = NativeSyntheticEvent<{
  layout: LayoutRectangle;
  target?: {
    style: TextStyle;
    childNodes: Node[];
  };
}>;

interface Props {
  categories: CategoryType[];
  productLibrary: LibraryType;
  clickProduct: (product: ProductType) => void;
  clickCombo: (combo: ComboType) => void;
  scrollY?: number;
  setCategoryScroll: (categoryIdx: number) => void;
  updateCategoryLocation: (categoryIdx: number, location: number) => void;
  menuView: 0 | 1;
  setMenuView: (menuView: 0 | 1) => void;
}

const MenuItems = ({
  categories,
  productLibrary,
  clickProduct,
  clickCombo,
  scrollY,
  setCategoryScroll,
  updateCategoryLocation,
  menuView,
  setMenuView,
}: Props) => {
  const {
    isMobile,
    screenSize: { height, width },
  } = useSelector((state: RootState) => state.appInfo);

  const [visible, setVisible] = useState(false);
  const [variation, setVariation] = useState(0);

  const menuOpacity = useRef(new Animated.Value(0)).current;

  const categoryRefs: React.RefObject<any>[] = categories.map(() =>
    useRef(createRef())
  );
  useEffect(() => {
    if (categories.length > 0 && !visible) {
      animate(menuOpacity, { toValue: 1, duration: 700 }).start();
      setVisible(true);
    }
  }, [categories]);

  useEffect(() => {
    const checkCategories = async () => {
      const refPlacements = categoryRefs.map(() => ({ top: 0, bottom: 0 }));

      const placementPromises = categoryRefs.map((ref, idx) => {
        return new Promise((res) => {
          ref?.current?.measure(
            (
              _fx: any,
              _fy: any,
              _width: any,
              height: any,
              _px: any,
              py: any
            ) => {
              refPlacements[idx] = { top: py, bottom: py + height };
              res(true);
            }
          );
        });
      });

      await Promise.all(placementPromises);

      let newActiveIdx: number | null = null;
      refPlacements.forEach(({ top, bottom }, idx) => {
        if (newActiveIdx !== null) return;
        const topMark = isMobile ? 0.2 : 0.1;
        if (
          (top >= topMark * height && top < 0.5 * height) ||
          (bottom > 0.7 * height && bottom < height)
        )
          newActiveIdx = idx;
      });

      if (newActiveIdx !== null) setCategoryScroll(newActiveIdx);
    };

    checkCategories();
  }, [scrollY]);

  const measureName = useCallback((event: WebLayoutChangeEvent) => {
    const target = event.nativeEvent.target;
    if (!target) return;
    const range = document.createRange();
    target.style.maxWidth = '100%';
    const text = target.childNodes[0];
    range.setStartBefore(text);
    range.setEndAfter(text);
    const clientRect = range.getBoundingClientRect();
    target.style.maxWidth = `${clientRect.width + 3}px`;
  }, []);

  const switchView = useCallback(() => {
    const variations = ['current', 'purple'];
    setVariation((prevVariation) => {
      let newVariation = (prevVariation += 1);
      if (newVariation === variations.length) newVariation = 0;
      return newVariation;
    });
  }, []);

  const combos = getCombos(productLibrary);

  const desktopMode = !!isDesktop || !isMobile;

  return (
    <Animated.View style={[styles.menuItemsWrapper, { opacity: menuOpacity }]}>
      {combos.length > 0 && (
        <View style={styles.categoryBox}>
          <TitleText style={styles.categoryName} spacing={0.5} center={true}>
            MEALS
          </TitleText>
          <BodyText fontSize={16} mt={4} color='midDark' center={true}>
            Try fan favorite tasty combinations.
          </BodyText>

          {!desktopMode ? (
            <FlatList
              data={combos}
              horizontal={true}
              showsHorizontalScrollIndicator={true}
              style={{ width: '100%' }}
              renderItem={({ item }) => {
                return (
                  <ComboBox
                    combo={item}
                    clickCombo={() => clickCombo(item)}
                    productLibrary={productLibrary}
                  />
                );
              }}
            />
          ) : (
            <View style={styles.desktopProductGrid}>
              {combos.map((combo) => (
                <ComboBox
                  combo={combo}
                  clickCombo={() => clickCombo(combo)}
                  productLibrary={productLibrary}
                  key={combo.id}
                />
              ))}
            </View>
          )}
        </View>
      )}

      {/* <MenuToggle menuView={menuView} setMenuView={setMenuView} /> */}

      {categories.map((category, cIdx) => {
        const products = category.products
          .map((p) => productLibrary[p])
          .filter(Boolean);

        const sortedProducts = sortBy(products, (p) => !p.inStock);

        const numColumns =
          category.name.toLocaleLowerCase() === 'pizzas'
            ? Math.ceil(sortedProducts.length / 2)
            : sortedProducts.length;

        return (
          <View
            style={styles.categoryBox}
            ref={categoryRefs[cIdx]}
            onLayout={(event) => {
              const offset = event?.nativeEvent?.layout?.y;
              if (offset >= 0) updateCategoryLocation(cIdx, offset);
            }}
            key={category.id}>
            <TitleText style={styles.categoryName} spacing={0.5} center={true}>
              {category.name.toUpperCase()}
            </TitleText>

            <BodyText style={styles.categoryDescription} center={true}>
              {category.description}
            </BodyText>

            {!desktopMode ? (
              <ScrollView
                horizontal
                showsVerticalScrollIndicator={isMobile && isWeb ? true : false}
                showsHorizontalScrollIndicator={isMobile ? true : false}
                contentContainerStyle={{
                  paddingVertical: 8,
                }}>
                <FlatList
                  data={sortedProducts}
                  // numColumns={2}
                  numColumns={numColumns}
                  scrollEnabled={isWeb ? true : false}
                  contentContainerStyle={{
                    alignSelf: 'flex-start',
                    paddingVertical: 10,
                    paddingLeft: 0,
                  }}
                  showsVerticalScrollIndicator={true}
                  showsHorizontalScrollIndicator={true}
                  keyExtractor={(item) => item.id}
                  renderItem={({ item }: any) => {
                    const product = item;

                    return (
                      <ProductButton1
                        product={product}
                        selectProduct={clickProduct}
                      />
                    );
                  }}
                />
              </ScrollView>
            ) : (
              <View style={styles.desktopProductGrid}>
                {sortedProducts.map((product) => (
                  <ProductButton1
                    product={product}
                    selectProduct={clickProduct}
                    key={product.id}
                  />
                ))}
              </View>
            )}
          </View>
        );
      })}
      <Block height={40} />
    </Animated.View>
  );
};

export default MenuItems;
