import React, { useState, useCallback } from 'react';
import { View, Pressable } from 'react-native';

import {
  getPacificTimeString,
  getPacificHours,
  linkToMap,
  eventLinkToMap,
} from '../../../helpers';

import { BodyText } from '../../../components';

import { LocationButton } from '../../../assets';

import styles from './styles';

const minPillWidth = 140;
const expandedMinWidth = minPillWidth * 1.2;

interface EventProps {
  event: StoreEventType;
  rowWidth: number;
  backgroundColor: string;
  setRowWidth?: (width: number) => void;
}

const EventPill = ({
  event,
  rowWidth,
  setRowWidth,
  backgroundColor,
}: EventProps) => {
  const [expanded, setExpanded] = useState(false);

  const viewLocation = useCallback(() => {
    if (event.address) return eventLinkToMap(event);
    if (event.store) return linkToMap(event.store);
  }, [event]);

  const addressComponents = event.address
    ? event.address.split(',')
    : !event.store?.mobile && event.store?.address
    ? event.store.address.split(',')
    : null;
  const { left, width } = getPillDimensions(event, rowWidth, expanded);

  const getTrimTime = (time: string) =>
    getPacificTimeString(time, { trim: true });

  return (
    <View
      style={[styles.eventBox, expanded && styles.expandedBox]}
      onLayout={(event) => {
        if (setRowWidth) setRowWidth(event?.nativeEvent?.layout?.width || 0);
      }}>
      <Pressable
        onPress={() => setExpanded((prev) => !prev)}
        style={[
          styles.eventPill,
          {
            left,
            width,
            backgroundColor,
            minWidth: expanded ? expandedMinWidth : minPillWidth,
          },
        ]}>
        <View style={styles.eventDetails}>
          <BodyText
            style={styles.storeName}
            color='white'
            lineHeight={expanded ? 18 : 16}
            bold={true}
            fontSize={expanded ? 16 : 14}
            center={true}>
            {event.name || event.store?.name}
          </BodyText>
          {expanded && !!addressComponents && (
            <>
              <BodyText center={true} color='white' lineHeight={14} mt={5}>
                {addressComponents[0]}
              </BodyText>
              <BodyText center={true} color='white' lineHeight={14} mb={5}>
                {addressComponents[1]}, {addressComponents[2].slice(0, 3)}
              </BodyText>
            </>
          )}

          <BodyText color='white' fontSize={expanded ? 14 : 13} bold={false}>
            {getTrimTime(event.openAt)}
            {' - '}
            {getTrimTime(event.closeAt)}
          </BodyText>
        </View>
        {expanded && (event?.address || !!event.store.address) && (
          <Pressable onPress={viewLocation} style={styles.locationButton}>
            <LocationButton width={14} height={14} />

            <BodyText color='white' center={true} ml={4}>
              View Location
            </BodyText>
          </Pressable>
        )}
      </Pressable>
    </View>
  );
};

export default EventPill;

const getPillDimensions = (
  event: StoreEventType,
  rowWidth: number,
  expanded: boolean
) => {
  const totalhours = 16;
  const openDate = new Date(event.openAt);
  const closeDate = new Date(event.closeAt);

  const openHour = getPacificHours(event.openAt);
  const closeHour = getPacificHours(event.closeAt);

  const openValue = openHour + openDate.getMinutes() / 60;
  let closeValue = closeHour + closeDate.getMinutes() / 60;
  if (closeValue < 12) closeValue += 24;

  let left: string | number = 0;
  let width: number | string = 0;
  if (rowWidth) {
    left = openValue > 10 ? ((openValue - 10) / totalhours) * rowWidth : 0;
    const minWidth = expanded ? expandedMinWidth : minPillWidth;
    if (left + minWidth >= rowWidth) left -= left + minWidth - rowWidth;
    const openHours = closeValue - openValue;
    width = (openHours / totalhours) * rowWidth;
    if (width < minWidth) {
      const extraWidth = minWidth - width;
      left -= extraWidth / 2;
      if (left < 0) left = 0;
      width = minWidth;
    }
    const remainingSpace = rowWidth - left;
    if (width >= remainingSpace) left -= width - remainingSpace + 2;
    if (left <= 0) {
      width += left - 5;
      left = 0;
    }
  } else {
    left = openValue > 12 ? `${((openValue - 12) / 24) * 100}%` : 0;
    width = `${((closeValue - openValue) / 8) * 100}%`;
  }

  return { left, width };
};
