import React, { useState, useCallback, useEffect } from 'react';
import {
  View,
  StyleSheet,
  Platform,
  useWindowDimensions,
  StatusBar,
} from 'react-native';
import { Provider } from 'react-redux';
import { NativeRouter } from 'react-router-native';

import { MOCK_TESTING, forceMockMode } from '@app/core';

import globalStore, { refreshStore } from './globalStore';

import WebAuthProvider from './providers/WebAuthProvider';
import NativeAuthProvider from './providers/NativeAuthProvider';
import MockAuthProvider from './providers/MockAuthProvider';

import {
  BrowserRouter,
  AppInfo,
  ErrorBoundary,
  WebImageLoader,
} from './components';

import Backdrop from './pages/Backdrop';

import AppRoutes from './AppRoutes';

import { initSentry, colors, writeStorage, readStorage } from './helpers';
import { mockAuth } from './helpers/useAuth';

initSentry();

export let setGlobalMockMode: () => void;
export let clearGlobalMockMode: () => void;

export default function App() {
  const [pageOpacity, setPageOpacity] = useState(0);
  const [appReady, setAppReady] = useState(true);

  const { height } = useWindowDimensions();

  useEffect(() => {
    setGlobalMockMode = setMockMode;
    clearGlobalMockMode = clearMockMode;
  }, []);

  const showContent = useCallback(() => {
    setPageOpacity(1);
  }, [setPageOpacity]);

  const setMockMode = useCallback(() => {
    setAppReady(false);
    setPageOpacity(0);
    forceMockMode();
    mockAuth();
    refreshStore();
    setTimeout(() => {
      setAppReady(true);
    }, 1);
  }, []);

  const clearMockMode = useCallback(() => {
    setAppReady(false);
    setPageOpacity(0);
    forceMockMode(false);
    mockAuth(false);
    refreshStore();
    setTimeout(() => {
      setAppReady(true);
    }, 1);
  }, []);

  const isNative = Platform.OS === 'ios' || Platform.OS === 'android';

  if (!appReady)
    return (
      <View
        style={[styles.appWrapper, { height, backgroundColor: colors.primary }]}
      />
    );

  const AuthProvider = MOCK_TESTING
    ? MockAuthProvider
    : isNative
    ? NativeAuthProvider
    : WebAuthProvider;

  const Router = isNative ? NativeRouter : BrowserRouter;

  return (
    <AuthProvider>
      <View
        style={[
          styles.appWrapper,
          {
            height,
            backgroundColor: pageOpacity ? colors.body : colors.primary,
          },
        ]}>
        <ErrorBoundary>
          <Provider store={globalStore}>
            <Router>
              <AppInfo />
              {!isNative && <WebImageLoader />}
              <Backdrop showContent={showContent} />
              <StatusBar hidden={false} barStyle='dark-content' />

              <View style={{ opacity: pageOpacity, height: '100%' }}>
                <AppRoutes />
              </View>
            </Router>
          </Provider>
        </ErrorBoundary>
      </View>
    </AuthProvider>
  );
}

const styles = StyleSheet.create({
  appWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#FBF3EB',
  },
});
