import { StyleSheet } from 'react-native';
import { colors, globalStyles, isIos } from '../../../helpers';

const styles = StyleSheet.create({
  modalBackdrop: {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(48,32,26,.8)',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  modalBox: {
    width: 368,
    maxWidth: '90%',
    height: 571,
    borderRadius: 24,
    backgroundColor: colors.secondary,
    alignSelf: 'center',
    overflow: 'hidden',
    paddingHorizontal: 16,
  },
  headerBanner: {
    position: 'absolute',
    left: 0,
    top: isIos ? 34 : 47,
    width: '120%',
    height: 140,
  },
  background: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 80,
    left: 0,
    borderTopLeftRadius: 300,
    transform: [{ scaleX: 4 }],
    backgroundColor: colors.white,
  },
  body: {
    ...globalStyles.flexColumn,
    flex: 1,
    paddingTop: 120,
    with: '100%',
  },
  topRow: {
    ...globalStyles.flexRow,
    position: 'relative',
    height: 40,
    width: '100%',
  },
  arrowBox: {
    ...globalStyles.flexRow,
    position: 'absolute',
    // top: isIos ? '28%' : '24%',
    left: 0,
    backgroundColor: colors.secondary,
    borderRadius: 25,
    width: 40,
    height: 40,
  },
  newArrow: {
    position: 'relative',
    width: 180,
  },
  missingCode: {
    // position: 'absolute',
    // width: '100%',
  },
  resendButton: {
    ...globalStyles.flexRow,
    padding: 10,
    marginLeft: 14,
    paddingHorizontal: 16,
    backgroundColor: colors.error,
    borderRadius: 25,
  },
  inputGroup: {
    width: '100%',
  },
  phoneInput: {
    color: colors.textDark,
    borderWidth: 1,
    borderColor: colors.midLight,
    backgroundColor: colors.body,
    paddingVertical: 10,
    paddingHorizontal: 16,
    fontSize: 16,
    lineHeight: 24,
    borderRadius: 4,
    opacity: 0.9,
    width: '100%',
    marginTop: 20,
  },
  focusedInput: {
    borderWidth: 1,
    outlineStyle: 'none',
  },
  errorMessage: {
    // display: 'none',
    height: 20,
    marginTop: 6,
  },

  button: {
    width: '100%',
    marginTop: 20,
    outlineStyle: 'none',
  },
  disclosureRow: {
    ...globalStyles.flexRow,
  },
});

export default styles;
