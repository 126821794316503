import React from 'react';
import { View, ViewStyle } from 'react-native';

interface Props {
  height?: number | string;
  grow?: boolean;
  style?: ViewStyle;
}

const Block = ({ height, style, grow }: Props) => {
  const extraStyle: ViewStyle = {
    height: height || 20,
    width: '100%',
    minHeight: height || 0,
  };

  if (grow) extraStyle.flexGrow = 1;

  return <View style={[style, extraStyle]} />;
};

export default Block;
