import { StyleSheet } from 'react-native';

import { colors, globalStyles } from '../../helpers';

const styles = StyleSheet.create({
  pageWrapper: {
    height: '100%',
    width: '100%',
    backgroundColor: colors.body,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    paddingTop: 40,
    paddingBottom: 20,
    marginBottom: 40,
  },
  exploreButton: {
    paddingVertical: 14,
    paddingHorizontal: 46,
    borderRadius: 4,
    backgroundColor: colors.secondary,
    marginBottom: 25,
  },
  storeContainer: {
    marginBottom: 40,
    borderRadius: 8,
    borderColor: colors.grey,
    backgroundColor: colors.white,
    width: 410,
    maxWidth: '94%',
    overflow: 'hidden',
    borderWidth: 1,
    // borderTopWidth: 1,
  },
  storeBox: {
    display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'c',
    width: '100%',
    minHeight: 95,
    paddingVertical: 15,
    paddingHorizontal: '5%',
    alignSelf: 'center',
    overflow: 'hidden',
    // borderWidth: 2,
    // borderColor: 'aqua',
  },
  storeDetailsBox: {
    ...globalStyles.flexRow,
    justifyContent: 'space-between',
    width: '100%',
  },
  innerBox: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    maxWidth: '65%',
    marginVertical: 0,
  },
  closedStoreBox: {
    flexDirection: 'column',
    justifyContent: 'center',
    width: 410,
    maxWidth: '100%',
    minHeight: 125,
    alignSelf: 'center',
    borderColor: colors.midLight,
    cursor: 'default',
  },
  openingStoreBox: {
    paddingHorizontal: 0,
    paddingVertical: 0,
    borderRadius: 8,
    backgroundColor: colors.white,
    marginBottom: 14,
  },
  openingContent: {
    paddingHorizontal: '5%',
    paddingTop: 10,
  },
  hoverLabel: {
    color: '#FFF',
    // fontSize: 18,
  },
  label: {
    color: colors.secondary,
    fontSize: 20,
    fontWeight: '800',
  },
  sublabel: {
    color: colors.text,
    fontSize: 16,
    // marginVertical: 6,
  },
  infoRow: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-start',
  },
  pill: {
    ...globalStyles.flexRow,
    alignSelf: 'flex-start',
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 50,
  },
  open: {
    marginTop: 1,
    marginBottom: 5,
    right: 3,
    backgroundColor: colors.green,
  },
  closed: {
    backgroundColor: colors.dimMaroon,
  },
  opening: {
    backgroundColor: colors.green,
  },
  orderButton: {
    ...globalStyles.flexRow,
    // flex: 1,
    width: 100,
    height: 40,
    // marginLeft: 20,
    backgroundColor: colors.primary,
    borderRadius: 5,
    letterSpacing: 0.4,
    color: colors.white,
    alignSelf: 'center',
  },
  mapView: {
    ...globalStyles.flexRow,
    alignSelf: 'center',
    width: '100%',
    height: 175,
  },
  mapLink: {
    ...globalStyles.flexRow,
    backgroundColor: colors.white,
    height: 35,
    width: '100%',
  },
  mapButton: {
    ...globalStyles.flexRow,
    alignSelf: 'center',
    width: '100%',
    height: 45,
    borderTopWidth: 1,
    borderColor: colors.midLight,
  },
});

export default styles;
