import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchMenu,
  fetchStores,
  fetchStore,
  fetchStoreEvents,
  fetchGenericMenu,
} from './actions';
import { cloneDeep } from 'lodash';

type WaitTimePayload = {
  storeId: string;
  waitTime: number;
  storeClosed?: boolean;
};

type State = {
  stores: StoreType[];
  storesEmpty: boolean;
  events: StoreEventType[];
  storeMenus: { [id: string]: MenuType };
  activeProduct: ProductType | null;
  activeCombo: ComboType | null;
  genericProduct: ProductType | null;
  genericMenu: MenuType | null;
};

const initialState: State = {
  stores: [],
  storesEmpty: false,
  events: [],
  storeMenus: {},
  activeProduct: null,
  activeCombo: null,
  genericMenu: null,
  genericProduct: null,
};

const storeSlice = createSlice({
  name: 'storeInfo',
  initialState,
  reducers: {
    setActiveProduct: (state, { payload }: { payload: ProductType }) => {
      state.activeProduct = payload;
    },
    clearActiveProduct: (state) => {
      state.activeProduct = null;
    },
    setActiveCombo: (state, { payload }: { payload: ComboType }) => {
      state.activeCombo = payload;
    },
    setGenericProduct: (state, { payload }: { payload: ProductType }) => {
      state.genericProduct = payload;
    },
    clearGenericProduct: (state) => {
      state.genericProduct = null;
    },
    updateStoreStatus: (state, { payload }: { payload: WaitTimePayload }) => {
      const { storeId, waitTime, storeClosed } = payload;
      const storeIndex = state.stores.findIndex((s) => s.id === storeId);

      if (storeIndex >= 0) {
        const store = state.stores[storeIndex];
        if (waitTime > 2) store.waitTime = waitTime;
        if (storeClosed !== undefined) store.open = !storeClosed;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(loadStores.fulfilled, (state, { payload }) => {
      if (payload.stores.length > 0) {
        const currentStores = cloneDeep(state.stores);
        const payloadIds = payload.stores.map((s: any) => s.id);
        currentStores.forEach(
          (cs: any) => !payloadIds.includes(cs.id) && payload.stores.push(cs)
        );
        state.stores = payload.stores;
      }
    });
    builder.addCase(loadStores.rejected, (state, { payload }: any) => {
      if (state.stores.length <= 0) state.storesEmpty = true;
    });
    builder.addCase(loadStoreEvents.fulfilled, (state, { payload }) => {
      state.events = payload?.events || state.events;
    });
    builder.addCase(loadStore.fulfilled, (state, { payload }) => {
      const { store } = payload;
      if (store && store.id) {
        const currentIdx = state.stores.findIndex((s) => s.id === store.id);
        if (currentIdx >= 0) state.stores[currentIdx] = store;
        else state.stores.push(store);
      }
    });
    builder.addCase(loadMenu.fulfilled, (state, { payload }) => {
      const { menu } = payload;
      if (menu && menu?.id) state.storeMenus[menu.storeId] = menu;
    });
    builder.addCase(loadGenericMenu.fulfilled, (state, { payload }) => {
      const { genericMenu } = payload;
      if (!!genericMenu && genericMenu.id) state.genericMenu = genericMenu;
    });
  },
});

export const loadStores = createAsyncThunk(
  'stores/loadStores',
  async (_, { dispatch, rejectWithValue }) => {
    const { stores } = await fetchStores();

    if (!stores) return rejectWithValue({ error: 'no stores loaded' });
    dispatch(loadStoreEvents());

    const activeStores = stores.filter((s: any) => s.events.length || s.open);

    activeStores.forEach((s: StoreType) => dispatch(loadMenu(s.id)));
    return { stores };
  }
);

export const loadStoreEvents = createAsyncThunk(
  'stores/loadStoreEvents',
  async () => {
    const { events, error } = await fetchStoreEvents();
    if (events) return { events };
  }
);

export const loadStore = createAsyncThunk(
  'stores/loadStore',
  async (storeId: string) => {
    const { store } = await fetchStore(storeId);
    return { store };
  }
);

export const loadMenu = createAsyncThunk(
  'stores/loadMenu',
  async (storeId: string, {}) => {
    const { menu } = await fetchMenu(storeId);
    return { menu };
  }
);

export const loadGenericMenu = createAsyncThunk(
  'stores/loadGenericMenu',
  async () => {
    const { genericMenu } = await fetchGenericMenu();
    return { genericMenu };
  }
);

export default storeSlice;
