import { StyleSheet } from 'react-native';

import { globalStyles, colors } from '../../../helpers';

const styles = StyleSheet.create({
  buttonBox: {
    position: 'relative',
    width: 308,
    height: 220,
    marginHorizontal: 12,
    marginBottom: 24,
    backgroundColor: colors.white,
  },
  sideButton: {
    width: 280,
    height: 250,
  },
  button: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  face: {
    ...globalStyles.flexColumn,
    position: 'relative',
    backgroundColor: colors.secondary,
    borderWidth: 0,
    borderColor: colors.secondary,
    width: '100%',
    height: '100%',
    borderRadius: 2,
    overflow: 'hidden',
    zIndex: 4,
  },
  rightEdge: {
    position: 'absolute',
    right: '-2%',
    bottom: '-.9%',
    backgroundColor: colors.text,
    borderRadius: 1,
    borderTopLeftRadius: 4,

    borderBottomRightRadius: 5,
    shadowColor: colors.text,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 1,
    shadowOpacity: 1,
    // backgroundColor: 'rgba(100,100,100,1)',
    // transform: [{ rotate: '180deg' }, { skewY: '45deg' }],
  },
  bottomEdge: {
    position: 'absolute',
    bottom: '-2%',
    right: '-.6%',
    borderRadius: 2,
    shadowColor: colors.text,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 1,
    shadowOpacity: 1,
    borderBottomRightRadius: 5,

    // transform: [{ rotate: '0deg' }, { skewX: '45deg' }],
    // backgroundColor: 'rgba(140,140,140,1)',
    backgroundColor: colors.text,
  },
  priceTag: {
    ...globalStyles.flexRow,
    justifyContent: 'flex-end',
    position: 'absolute',
    top: 0,
    right: 0,
    height: 50,
    paddingRight: 8,

    // transform: [{ rotate: '20deg' }],
  },
  stockImage: {
    position: 'absolute',
    left: -10,
    top: -10,
    zIndex: 200,
  },
  productPrice: {
    fontSize: 18,
    fontWeight: '700',
    color: colors.white,
    textAlign: 'right',
  },
  imageBox: {
    height: 135,
    width: '85%',
    alignSelf: 'flex-start',
    overflow: 'hidden',
  },
  otherImageBox: {
    ...globalStyles.flexRow,
    height: 185,
    width: '100%',
  },
  pizzaImage: {
    right: 230,
    bottom: 130,
    width: '195%',
    height: '196%',
    // transform: [{ rotateY: '180deg' }],
    // transform: [{ scaleX: 1.02 }],
  },
  dipImage: {
    width: '70%',
    height: 100,
  },
  drinkImage: {
    width: '50%',
    height: 140,
    top: 20,
    alignSelf: 'flex-start',
  },
  otherImage: {
    width: '70%',
    height: 100,
  },
  textBox: {
    ...globalStyles.flexColumn,
    justifyContent: 'center',
    position: 'absolute',
    height: 120,
    bottom: 0,
    paddingHorizontal: 10,
    paddingTop: 10,
    width: '98%',
  },
  sideText: {
    width: '100%',
    // bottom: 50,
  },
});

export default styles;
