import React, { useRef, useEffect, useCallback, useState } from 'react';
import { View, Pressable, Animated, LayoutChangeEvent } from 'react-native';

import { BodyText, Close, Star, CouponIcon } from '..';

// import { CouponIcon } from '../assets';

import { globalStyles, colors, isIos, animate } from '../../helpers';

import CouponStars from './CouponStars';

import styles from './styles';

interface Props {
  coupon: CouponType;
  clickCoupon?: () => void;
  removeCoupon?: () => void;
  selected?: boolean;
  showStars?: boolean;
  count?: number;
  dim?: boolean;
}

const stars = [
  { left: 5, top: 14, size: 5 },
  { left: 13, top: 6, size: 7 },
  { left: 26, top: 4, size: 4 },
  //
  { right: 13, bottom: 4, size: 4 },
  { right: 4, bottom: 6.7, size: 5 },
  { right: 2, bottom: 18, size: 4 },
];

const CouponTag = ({
  coupon,
  clickCoupon,
  removeCoupon,
  selected,
  showStars,
  count,
  dim,
}: Props) => {
  if (!coupon) return null;

  const [size, setSize] = useState({ width: 0, height: 0 });

  const marginAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (selected) animate(marginAnim, { toValue: 1, duration: 400 }).start();
    else animate(marginAnim, { toValue: 0, duration: 400 }).start();
  }, [selected]);

  const updateSize = useCallback((event: LayoutChangeEvent) => {
    if (!event.nativeEvent) return;
    const layout = event.nativeEvent?.layout;

    const { width, height } = layout;
    setSize({ width, height });
  }, []);

  const contentColor = selected && showStars ? 'white' : 'white';

  const marginBottom = marginAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [10, 20],
  });
  return (
    <Animated.View
      style={[
        styles.couponTag,
        selected && styles.selectedTag,
        // { marginBottom },
      ]}>
      <Pressable
        onPress={clickCoupon}
        onLayout={updateSize}
        style={{ backgroundColor: dim ? colors.dimMaroon : colors.secondary }}>
        {selected && showStars && <CouponStars couponSize={size} />}
        <View
          style={[
            styles.background,
            {
              backgroundColor: selected
                ? colors.barnRed
                : dim
                  ? colors.dimMaroon
                  : colors.secondary,
            },
          ]}
        />
        {!!removeCoupon && (
          <Pressable onPress={removeCoupon} style={styles.close}>
            <Close color='white' />
          </Pressable>
        )}

        <View style={styles.starWrapper}>
          <View style={styles.upperStars}>
            {stars.map((star, idx) => (
              <View
                style={[
                  styles.starBox,
                  {
                    top: star.top || 'auto',
                    left: star.left || 'auto',
                    bottom: star.bottom || 'auto',
                    right: star.right || 'auto',
                  },
                ]}
                key={idx}>
                <Star
                  width={star.size}
                  height={star.size}
                  color={contentColor}
                />
              </View>
            ))}
          </View>
        </View>
        <View style={styles.couponTextBox}>
          <View style={styles.titleRow}>
            <CouponIcon width={26} height={20} color={contentColor} />
            <BodyText
              fontSize={20}
              // style={{ borderWidth: 2 }}
              bold={true}
              ml={14}
              mb={isIos ? -1.5 : 2}
              color={contentColor}
              numberOfLines={1}>
              {coupon.slug}
            </BodyText>
            {!!count && count > 1 && (
              <View
                style={[styles.countBadge, selected && styles.adjustedBadge]}>
                <BodyText color='barnRed' bold={true}>
                  <BodyText
                    color='barnRed'
                    fontSize={16}
                    ml={1}
                    numberOfLines={1}>
                    {selected ? (
                      <>
                        <View style={{ transform: [{ translateY: -1 }] }}>
                          <BodyText
                            color='barnRed'
                            strike={false}
                            fontSize={15}>
                            {count}
                          </BodyText>
                          <BodyText
                            color='barnRed'
                            bold={true}
                            style={{
                              position: 'absolute',
                              transform: [
                                { scaleX: 1.5 },
                                { rotate: '45deg' },
                                { translateX: 1 },
                                { translateY: -2 },
                              ],
                            }}>
                            /
                          </BodyText>
                        </View>
                        <BodyText
                          color='barnRed'
                          ml={10}
                          strike={false}
                          fontSize={18}>
                          {count - 1}
                        </BodyText>
                      </>
                    ) : (
                      'x ' + count
                    )}
                  </BodyText>
                </BodyText>
              </View>
            )}
          </View>

          <BodyText
            color={contentColor}
            ml={10}
            mr={10}
            numberOfLines={4}
            // fontSize={14}
            lineHeight={20}
            center={true}>
            {coupon.description}
          </BodyText>
        </View>
      </Pressable>
    </Animated.View>
  );
};

export default CouponTag;
