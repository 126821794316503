import React, { useCallback, useState, createRef, useEffect } from 'react';
import {
  View,
  Pressable,
  Image,
  TextInput,
  KeyboardAvoidingView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-native';

import { MOCK_TESTING } from '../../../mockModes';

import { setLoginModal } from '../../../globalStore';

import { colors, useAuth, logMessage, logError } from '../../../helpers';

import { Button, BodyText, TitleText } from '../..';

import CustomTextInput from '../../TextInput';

import { loadUserInfo } from '../../AppInfo';

import { setGlobalMockMode } from '../../../App';

import BaseModal from '../BaseModal';

import { stellarBanner, BackArrow, Refresh } from '../../../assets';

import styles from './styles';

const MOCK_NUMBER = '19998889999';

const RESET_DELAY = MOCK_TESTING ? 1000 : 10000;

const prettyNumber = (number: string) => {
  let bareNumber = number.replace(/[^0-9]/g, '');
  if (bareNumber.length === 11 && bareNumber.startsWith('1'))
    bareNumber = bareNumber.slice(1);
  if (bareNumber.length !== 10) return number;
  const area = bareNumber.slice(0, 3);
  const trip = bareNumber.slice(3, 6);
  const rest = bareNumber.slice(6);
  const pretty = `(${area}) ${trip}-${rest}`;
  return pretty;
};

const LoginModal = () => {
  const { sendPhoneCode, submitVerificationCode } = useAuth();
  const dispatch = useDispatch();
  const { userCoupons, userOrders, referrerId } = useSelector(
    (state: RootState) => {
      const userCoupons = state.userInfo.userCoupons;
      const userOrders = state.userInfo.userOrders;
      const referrerId = state.userInfo.referrerId;
      return { userCoupons, userOrders, referrerId };
    }
  );

  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  let desiredRoute = '';
  if (pathname === '/') {
    if (referrerId) desiredRoute = pathname;
    else if (!!userCoupons && userCoupons?.length > 0)
      desiredRoute = '/profile/coupons';
    else if (userOrders.length > 0) desiredRoute = '/profile/orders';
    else desiredRoute = pathname;
  }

  const [localNumber, setLocalNumber] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [codeStep, setCodeStep] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [resendOption, showResendOption] = useState(false);
  const [missingCode, showMissingCode] = useState(false);
  const [resentCode, showResentCode] = useState(false);

  const phoneCodeRef: React.RefObject<TextInput> = createRef();
  const inputCodeRef: React.RefObject<TextInput> = createRef();

  useEffect(() => {
    if (!codeStep && phoneCodeRef.current) phoneCodeRef.current.focus();
    else if (inputCodeRef.current) inputCodeRef.current.focus();

    if (codeStep) {
      const resendTimer = setTimeout(() => showMissingCode(true), RESET_DELAY);
      return () => {
        clearTimeout(resendTimer);
      };
    }
  }, [codeStep]);

  const clearModal = useCallback(() => {
    dispatch(setLoginModal(false));
  }, [setLoginModal]);

  const updateNumber = useCallback(
    (value: string) => {
      const phoneNumber = value.replace(/[^0-9\-()]/g, '');
      setPhoneError('');
      setLocalNumber(phoneNumber.slice(0, 15));
    },
    [setLocalNumber]
  );

  const submitNumber = useCallback(async () => {
    let phoneNumber = localNumber.replace(/[^0-9]/g, '');
    if (phoneNumber === MOCK_NUMBER) {
      setGlobalMockMode();
      return clearModal();
    }
    if (phoneNumber.length === 11 && phoneNumber.startsWith('1'))
      phoneNumber = phoneNumber.slice(1);
    if (phoneNumber.length === 0)
      return setPhoneError('Please enter phone number to continue.');
    // if (phoneNumber.length !== 10)
    //   return setPhoneError('Please enter a valid phone number.');

    setSubmitting(true);

    const result = await sendPhoneCode(phoneNumber);
    if (result?.error) {
      const { error } = result;
      logError(error);
      setSubmitting(false);
      return setPhoneError(error);
    }
    setCodeStep(true);
    setSubmitting(false);
  }, [localNumber]);

  const resubmitNumber = useCallback(() => {
    logMessage(`Resending auth code - ${localNumber}`);
    const phoneNumber = localNumber.replace(/[^0-9]/g, '');
    sendPhoneCode(phoneNumber);
    showResentCode(true);
    showResendOption(false);
  }, [localNumber]);

  const submitCode = useCallback(async () => {
    if (verificationCode.length < 6) return setCodeError('Code is invalid');
    if (submitting) return;

    setSubmitting(true);
    setCodeError('');

    const phoneNumber = localNumber.replace(/[^0-9]/g, '');
    const result = await submitVerificationCode(
      phoneNumber,
      verificationCode,
      desiredRoute
    );
    if (result === 'success') {
      await loadUserInfo();
      setSubmitting(false);
      if (desiredRoute) navigate(desiredRoute);
      clearModal();
      return;
    }

    if (result.includes('Wrong phone number or verification code.'))
      setCodeError(result);
    if (result.includes('maximum number of attempts.'))
      setCodeError('Too many attempts, account is locked');

    if (result === 'access_denied')
      setCodeError('Code is invalid, check again or go back to resend');
    if (result === 'too_many_attempts')
      setCodeError('Too many attempts, account is locked');
    setSubmitting(false);
  }, [verificationCode, setCodeError, submitting, setSubmitting]);

  const goBack = useCallback(() => {
    setCodeError('');
    showMissingCode(false);
    showResendOption(false);
    showResentCode(false);
    setVerificationCode('');
    setCodeStep(false);
  }, [setCodeStep, setCodeError]);

  return (
    <BaseModal onBackdropPress={clearModal} testID='login-modal'>
      <View style={styles.modalBox} testID='login-modal-box'>
        <BodyText color='white' bold={true} fontSize={16} mt={30} center={true}>
          LOG IN
        </BodyText>
        <View style={styles.background} />
        <Image
          source={stellarBanner}
          style={styles.headerBanner}
          resizeMode='contain'
        />

        <View style={styles.body}>
          {!codeStep ? (
            <>
              <TitleText fontSize={30} color='primary'>
                WELCOME!
              </TitleText>
              <BodyText center={true} color='textDark' mt={8}>
                Enter your phone number so that we can send you a message when
                your order is ready
              </BodyText>
              <KeyboardAvoidingView style={styles.inputGroup}>
                <CustomTextInput
                  value={localNumber}
                  onChangeText={updateNumber}
                  style={styles.phoneInput}
                  focusStyle={styles.focusedInput}
                  placeholder='555-555-5555'
                  placeholderTextColor={colors.midLight}
                  autoFocus={true}
                  autoComplete='tel'
                  textContentType='telephoneNumber'
                  keyboardType='numeric'
                  testID='login-modal-phone-input'
                  onKeyPress={(e) =>
                    e.nativeEvent.key === 'Enter' && submitNumber()
                  }
                  inputRef={phoneCodeRef}
                />

                <BodyText
                  style={[styles.errorMessage, { opacity: phoneError ? 1 : 0 }]}
                  center={true}
                  color='error'>
                  {phoneError}
                </BodyText>

                <Button
                  color='secondary'
                  onClick={submitNumber}
                  label='CONTINUE'
                  style={styles.button}
                  submitting={submitting}
                />
              </KeyboardAvoidingView>

              <BodyText
                color='textDark'
                center={true}
                fontSize={12}
                mb={2}
                mt={16}
                lineHeight={16}>
                By providing your phone number, you agree to create an account
                with Stellar Pizza subject to our{' '}
                <BodyText
                  color='textDark'
                  bold={true}
                  fontSize={12}
                  link='https://www.eatstellarpizza.com/terms/'>
                  Terms of Use
                </BodyText>{' '}
                and{' '}
                <BodyText
                  color='textDark'
                  bold={true}
                  fontSize={12}
                  link='https://www.eatstellarpizza.com/privacy/'>
                  Privacy Policy.
                </BodyText>
              </BodyText>
            </>
          ) : (
            <>
              <View style={styles.topRow}>
                <Pressable
                  style={[styles.arrowBox, resendOption && styles.newArrow]}
                  onPress={goBack}>
                  <BackArrow width={16} height={16} />
                  {resendOption && (
                    <BodyText color='white' ml={8} bold={true}>
                      CHANGE NUMBER
                    </BodyText>
                  )}
                </Pressable>
                {missingCode && (
                  <View style={styles.missingCode}>
                    {resendOption ? (
                      <Pressable
                        style={styles.resendButton}
                        onPress={resubmitNumber}>
                        <Refresh width={16} height={16} />
                        <BodyText
                          color='white'
                          center={true}
                          ml={10}
                          bold={true}>
                          RESEND
                        </BodyText>
                      </Pressable>
                    ) : !resentCode ? (
                      <BodyText
                        fontSize={16}
                        color='error'
                        underline={true}
                        onPress={() => {
                          showResendOption(true);
                          logMessage(`Auth code not received - ${localNumber}`);
                        }}
                        center={true}>
                        Didn't receive a code?
                      </BodyText>
                    ) : (
                      <BodyText color='darkBlue' fontSize={16}>
                        New code sent
                      </BodyText>
                    )}
                  </View>
                )}
              </View>
              <BodyText center={true} color='textDark' fontSize={15} mt={30}>
                Enter the code sent to{' '}
                <BodyText bold={true}>{prettyNumber(localNumber)}</BodyText>
              </BodyText>

              <CustomTextInput
                value={verificationCode}
                onChangeText={setVerificationCode}
                style={styles.phoneInput}
                focusStyle={styles.focusedInput}
                placeholder=''
                placeholderTextColor={colors.midLight}
                autoFocus={true}
                autoComplete='sms-otp'
                textContentType='oneTimeCode'
                keyboardType='numeric'
                testID='login-modal-code-input'
                onKeyPress={(e) =>
                  e.nativeEvent.key === 'Enter' && submitCode()
                }
                inputRef={inputCodeRef}
              />
              <BodyText
                style={[styles.errorMessage, { opacity: codeError ? 1 : 1 }]}
                color='error'
                mt={5}>
                {codeError}
              </BodyText>

              <Button
                color='secondary'
                onClick={submitCode}
                label='CONTINUE'
                submitting={submitting}
                style={styles.button}
              />
            </>
          )}
        </View>
      </View>
    </BaseModal>
  );
};

export default LoginModal;
