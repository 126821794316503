import React, { useCallback, useEffect, useState } from 'react';
import { View, ScrollView, RefreshControl, Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-native';
import { orderBy } from 'lodash';

import { STORE_TESTING } from '@app/core';

import {
  BodyText,
  TitleText,
  CustomView,
  Block,
  CouponDisplay,
} from '../../components';

import {
  readStores,
  loadStores,
  readGenericMenu,
  readGenericProduct,
} from '../../globalStore';

import {
  logError,
  readQueryParam,
  removeQueryParam,
  colors,
} from '../../helpers';

import OpenStoreBox from './OpenStoreBox';
import OpeningStoreBox from './OpeningStoreBox';
import ClosedStoreBox from './ClosedStoreBox';
import UpcomingSchedule from './UpcomingSchedule';

import { SleepingClosedBolt } from '../../assets';

import styles from './styles';

const Stores = () => {
  const dispatch = useDispatch();
  const { storeId } = useParams();
  const navigate = useNavigate();
  const genericMenu = useSelector(readGenericMenu);
  const genericProduct = useSelector(readGenericProduct);
  let stores = useSelector(readStores);
  const storesEmpty = useSelector(
    (state: RootState) => state.storeInfo.storesEmpty
  );

  const selectedCoupon = useSelector(
    (state: RootState) => state.userInfo.selectedCoupon
  );

  let singleStoreView = false;

  if (storeId) {
    const store = stores.find((s) => s.id === storeId);
    if (store) {
      stores = [store];
      singleStoreView = true;
    }
  }

  const [pendingProduct, setPendingProduct] = useState<ProductType | null>(
    null
  );

  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    dispatch(loadStores());

    const checkProductParam = () => {
      const genericProductId = readQueryParam('genericProduct');
      if (genericProductId) {
        if (!genericMenu)
          return logError('No generic menu present in store selection');
        if (!genericProduct)
          return logError('No generic product present in store selection');
        if (genericProduct.id !== genericProductId)
          return logError('Generic product does not match id in url');

        setPendingProduct(genericProduct);
        removeQueryParam('genericProduct');
      }
    };
    checkProductParam();
  }, []);

  const setStore = useCallback(
    async (storeId: string) => {
      const clickedStore = stores.find((s) => s.id === storeId);
      if (!clickedStore || (!clickedStore?.open && !STORE_TESTING)) return;

      navigate(`/store/${clickedStore.id}`);
    },
    [pendingProduct, stores]
  );

  const refreshStores = useCallback(async () => {
    setRefreshing(true);
    await dispatch(loadStores());
    setRefreshing(false);
  }, []);

  const openStores: StoreType[] = [];
  const openingStores: StoreType[] = [];
  const closedStores: StoreType[] = [];

  stores.forEach((s) => {
    if (s.open || withinOperatingHours(s)) openStores.push(s);
    else if (openingSoon(s)) openingStores.push(s);
    else if (!!s.events?.length || s.openAt || s.closeAt) closedStores.push(s);
  });

  const storesClosed =
    (stores.length > 0 &&
      openStores.length === 0 &&
      openingStores.length === 0) ||
    storesEmpty;

  return (
    <ScrollView
      scrollsToTop={true}
      refreshControl={
        <RefreshControl
          refreshing={refreshing}
          onRefresh={refreshStores}
          tintColor={colors.primary}
        />
      }>
      <Block height={20} />
      <CouponDisplay removeCoupon={true} />
      {!!selectedCoupon && (
        <BodyText
          center={true}
          bold={true}
          color='textDark'
          fontSize={18}
          mt={20}
          ml={10}
          mr={10}>
          {storesClosed
            ? 'Stores are currently closed, check back later to use your coupon.'
            : 'Choose a store to get this coupon applied to your order.'}
        </BodyText>
      )}
      <View style={styles.pageWrapper}>
        {storesClosed && (
          <CustomView flexColumn={true} maxWidth='99%'>
            <SleepingClosedBolt width={200} height={200} />

            <BodyText
              color='secondary'
              fontSize={18}
              bold={true}
              center={true}
              mt={16}
              mb={16}>
              shh! (our trucks are sleeping)
            </BodyText>
            {!storesEmpty && (
              <View style={styles.exploreButton}>
                <BodyText
                  onPress={() => navigate('/menu')}
                  color='white'
                  fontSize={20}
                  bold={true}>
                  EXPLORE MENU
                </BodyText>
              </View>
            )}
          </CustomView>
        )}

        {openStores.length > 0 && (
          <TitleText color='primary' fontSize={28} mb={25} mt={10}>
            OPEN NOW
          </TitleText>
        )}

        {orderBy(openStores, ({ open }) => open || null).map((store) => (
          <OpenStoreBox
            store={store}
            setStore={setStore}
            expandHours={singleStoreView}
            key={store.id}
          />
        ))}

        {openingStores.length > 0 && (
          <>
            <Block height={20} />
            <TitleText color='secondary' fontSize={22} mb={18} mt={30}>
              OPENING SOON
            </TitleText>

            {orderBy(openingStores, ({ openAt }) => openAt || null).map(
              (store, idx) => (
                <OpeningStoreBox
                  store={store}
                  expandHours={singleStoreView}
                  key={store.id}
                />
              )
            )}
          </>
        )}

        {!singleStoreView && <UpcomingSchedule />}

        {closedStores.length > 0 && (
          <>
            <Block height={80} />
            <TitleText
              color='dimMaroon'
              fontSize={22}
              mb={8}
              mt={30}
              style={{ opacity: 0.85 }}>
              CLOSED
            </TitleText>

            {orderBy(
              closedStores,
              ({ openAt, closeAt, events }) =>
                openAt && closeAt ? 1000000000 : events?.length || 0,
              'desc'
            ).map((store, idx) => (
              <ClosedStoreBox
                store={store}
                firstStore={idx === 0}
                lastStore={idx === closedStores.length - 1}
                expandHours={true}
                key={store.id}
              />
            ))}

            <BodyText style={{ width: '80%' }} center={true} mt={10} mb={8}>
              *addresses are approximate, exact location shown when store is
              open
            </BodyText>
          </>
        )}

        <Block height={40} />
        {!!singleStoreView && (
          <Pressable
            onPress={() => navigate('/stores')}
            style={styles.exploreButton}>
            <BodyText color='white' fontSize={16} bold={false}>
              View All Stores
            </BodyText>
          </Pressable>
        )}
      </View>
    </ScrollView>
  );
};

export default Stores;

const withinOperatingHours = (store: StoreType) => {
  const { openAt, closeAt, preorderAt } = store;
  const now = new Date();
  const openDate = new Date(openAt);
  const closeDate = new Date(closeAt);
  const preorderDate = new Date(preorderAt);
  if (preorderAt && closeAt) return now >= preorderDate && now <= closeDate;
  if (openAt && closeAt) return now >= openDate && now <= closeDate;
  return false;
};

const openingSoon = (store: StoreType) => {
  const { openAt, preorderAt } = store;
  const now = new Date().getTime();
  const twoHours = 1000 * 60 * 60 * 2;

  if (preorderAt) {
    const preorderTime = new Date(preorderAt).getTime();
    if (preorderTime - now < twoHours && preorderTime - now > 0) return true;
  }

  if (openAt) {
    const openTime = new Date(openAt).getTime();
    if (openTime - now < twoHours && openTime - now > 0) return true;
  }
  return false;
};
